import React from 'react';
import { selectAuthUser, useSelector } from '@shared/redux';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';

export const PosthogIdentifyProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const posthog = useLocalPostHog();
  const authUser = useSelector(selectAuthUser);

  React.useEffect(() => {
    if (posthog && authUser?.email && authUser?.handle) {
      posthog.identify(authUser.email, {
        handle: authUser.handle,
      });
    }
  }, [posthog, authUser?.email, authUser?.handle]);

  return children;
};
