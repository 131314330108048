'use client';

import React from 'react';
import { Spin } from 'antd';
import { Box } from '@shared/ui/box';
import { CSSObject } from '@emotion/styled';
import { spinnerContainerStyles } from './styles';

function PageSpin({
  styles,
  fullscreen,
}: {
  styles?: CSSObject;
  fullscreen?: boolean;
}) {
  return (
    <Box
      boxStyles={{
        ...spinnerContainerStyles,
        ...(styles || {}),
      }}
    >
      <Spin size="large" fullscreen={fullscreen} />
    </Box>
  );
}

export default PageSpin;
