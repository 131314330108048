import { dynamicCards } from '@shared/constants';
import { Layout as ILayout } from 'react-grid-layout';
import { ComponentNames } from '@shared/constants/componentNames';
import { LayoutBreakpoints } from '@shared/constants/layoutBreakpoints';

export const transformComponents = (components: any[]) => {
  return components.reduce<{
    extraData: any;
    layouts: {
      [LayoutBreakpoints.sm]: ILayout[];
      [LayoutBreakpoints.xxs]: ILayout[];
    };
  }>(
    (acc, { mobile, desktop, name, ...rest }, index) => {
      const uid = `${name}_+_${index}`;

      acc.layouts[LayoutBreakpoints.sm].push({ ...desktop, i: uid });
      acc.layouts[LayoutBreakpoints.xxs].push({ ...mobile, i: uid });

      if (dynamicCards.includes(name)) {
        if (name === ComponentNames.basics_service_list) {
          acc.extraData[uid] = rest.service_list || [];
        } else {
          acc.extraData[uid] = {
            ...rest,
            uid,
          };
        }
      } else {
        acc.extraData[name] = {
          ...rest,
          uid,
          name,
        };
      }

      return acc;
    },
    {
      layouts: {
        [LayoutBreakpoints.sm]: [],
        [LayoutBreakpoints.xxs]: [],
      },
      extraData: {},
    },
  );
};
