import { CSSObject } from '@emotion/styled';

export const spinnerContainerStyles: CSSObject = {
  width: '100%',
  height: '100%',
  display: 'flex',
  marginTop: '4rem',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
};
