import { api } from '@/lib/services/api';
import { Templates } from '@/lib/types/templates';
import { urlHelper } from '@/lib/utils/urlHelper';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@/lib/utils/transformErrorResponse';

interface TemplatesResultType {
  data: {
    templates: Templates.Template[];
  };
}

interface TemplateResultType {
  data: Templates.Root;
}

export const { useFetchTemplateMutation, useFetchTemplatesMutation } =
  api.injectEndpoints({
    endpoints: (builder) => ({
      fetchTemplate: builder.mutation<any, number>({
        transformErrorResponse,
        query: (id) => ({
          method: 'GET',
          url: urlHelper(ApiEndpoints.fetchTemplate, { id }),
        }),
      }),
      fetchTemplates: builder.mutation<
        TemplatesResultType,
        { default: boolean } | void
      >({
        transformErrorResponse,
        query: (params) => ({
          method: 'GET',
          url: `${ApiEndpoints.mediakitBuilderTemplates}${
            params?.default ? '?default=true' : ''
          }`,
        }),
      }),
    }),
  });
